import styled from "styled-components";
import { colors } from "../../../utils/theme";

export const BannerContainer = styled.div`
  width: 100%;
  aspect-ratio: 8/3;
  padding: 0 2%;
  @media screen and (max-width: 768px) {
    padding: 0% 2% 4% 2%;
    aspect-ratio: 6/3;
  }
  @media screen and (max-width: 540px) {
    padding: 4% 6% 12% 6%;
    aspect-ratio: 3/3;
  }
  @media screen and (max-width: 420px) {
    padding: 4% 6% 8% 6%;
    aspect-ratio: 3/4;
  }
`;
export const BannerImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 1000px;
  //   height: 100%;
  border: 24px solid ${colors.opaqueBrown};
  object-position: ${({ objectPosition }) =>
    objectPosition ? objectPosition : "center"};
  box-shadow: 0 1px 4px rgba(7, 26, 27, 0.4) inset;
  aspect-ratio: 8/3;

  @media screen and (max-width: 768px) {
    border-radius: 100px;
    aspect-ratio: 6/3;
    border: none;
  }
  @media screen and (max-width: 540px) {
    border-radius: 100px;
    aspect-ratio: 3/4;
  }
`;
