import styled from "styled-components";
import { colors } from "../../utils/theme";

export const ParentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  justify-content: center;
  padding: ${({ padding }) => (padding ? padding : "0")};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 420px) {
    flex-wrap: wrap;
  }
`;
