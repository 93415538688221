import styled from "styled-components";
import { colors } from "../../utils/theme";

export const ChoiceComponentParent = styled.div`
  //   padding: 40px;
  background-color: ${colors.primaryLight};
  color: #715120;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    align-items: center;
  }
  @media screen and (max-width: 540px) {
    align-items: center;
    padding: 40px 20px;
  }
`;
export const Header = styled.div`
  font-size: 40px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
  @media screen and (max-width: 540px) {
    font-size: 28px;
    text-align: center;
  }
`;
export const Description = styled.div`
  font-size: 24px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  @media screen and (max-width: 540px) {
    font-size: 18px;
  }
`;
export const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #715120;
  //   border-radius: 32px;
  //   padding: 32px;
  @media screen and (max-width: 768px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 540px) {
    padding: 0px 24px 32px 24px;
    border-radius: 36px;
  }
`;
export const ChoicesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 540px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
export const Choice = styled.div`
  display: flex;
  flex-basis: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 0;

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 540px) {
    padding: 24px 0;
    justify-content: flex-start;
  }
`;
export const ChoiceTitle = styled.div`
  font-size: 18px;
  background-color: #3d8696;
  color: white;
  border-radius: 1000px;
  padding: 16px 32px;
  padding: 16px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    padding: 8px 16px;
  }
  @media screen and (max-width: 540px) {
    font-size: 18px;
    padding: 4px 8px;
  }
`;
export const ChoiceImage = styled.img`
  width: 72%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 540px) {
    width: 120%;
  }
`;

export const TitleContainerImage = styled.img`
  width: ${({ width }) => (width ? width : "96px")};
  -webkit-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  -moz-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  -ms-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  -o-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(293deg)
    brightness(100%) contrast(104%);
  @media screen and (max-width: 1024px) {
    width: ${({ width }) => (width ? width * 0.85 + "px" : "96px")};
  }
  @media screen and (max-width: 768px) {
    width: ${({ width }) => (width ? width * 0.8 + "px" : "96px")};
  }
  @media screen and (max-width: 540px) {
    width: ${({ width }) => (width ? width * 0.2 + "px" : "96px")};
  }
`;

export const VerticalSpacer = styled.span`
  margin: ${({ spacerHeight }) => (spacerHeight ? spacerHeight : "0px")} 0 0 0;
`;

export const HorizontalSpacer = styled.div`
  margin: 0 ${({ spacerWidth }) => (spacerWidth ? spacerWidth : "4px")} 0 0;
`;
