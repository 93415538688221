import React, { useEffect } from "react";
import FormComponent from "../../../componentsRepo/formComponent/formComponent";
import MapComponent from "../../../componentsRepo/mapComponent/mapComponent";
import GridView from "./../../../componentsRepo/gridViewComponent/gridView";
import { ContactInfoParent } from "./contactUsStyle";
import CardComponent from "./../../../componentsRepo/contentCard/contentCard";
import { colors } from "../../../utils/theme";

const addressObject = {
  cardPadding: "2% 0%",
  alignItems: "start",
  titleText: "Address:",
  titleTextSize: 24,
  titleFontWeight: "700",
  titleTextColor: colors.brown,
  descriptionText: "Master & Major Builders, Ltd. Nakuru, Kenya",
  descriptionTextSize: 18,
  descriptionTextColor: colors.brown,
};
const emailObject = {
  cardPadding: "2% 0%",
  alignItems: "start",
  titleText: "Email:",
  titleTextSize: 24,
  titleFontWeight: "700",
  titleTextColor: colors.brown,
  descriptionText: "masterhomes.mmb@gmail.com",
  descriptionTextSize: 18,
  descriptionTextColor: colors.brown,
};
const phoneObject = {
  cardPadding: "2% 0%",
  alignItems: "start",
  titleText: "Phone:",
  titleTextSize: 24,
  titleFontWeight: "700",
  titleTextColor: colors.brown,
  descriptionText: "+254 711 716 919 \\ +254 748 406 246",
  descriptionTextSize: 18,
  descriptionTextColor: colors.brown,
  itemSpacing: "0",
};

function CompanyInfo() {
  return (
    <ContactInfoParent>
      {/* <CardComponent {...titleObject} /> */}
      <CardComponent {...addressObject} />
      <CardComponent {...emailObject} />
      <CardComponent {...phoneObject} />
    </ContactInfoParent>
  );
}
export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <MapComponent />
      <GridView
        padding="2% 4%"
        desktopColumns={2}
        tabletColumns={2}
        phoneColumns={1}
        children={[<CompanyInfo />, <FormComponent />]}
      />
    </React.Fragment>
  );
}
