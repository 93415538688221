import styled from "styled-components";
import { Button } from "react-bootstrap";

export const GalleryCarouselRoot = styled.div`
	display: flex;
	flex-direction: row;
	flex:2;
	align-items: center;
    justify-content:center;
    width:fit-content;
	height:100%
`;
export const ButtonRight = styled(Button)`
	& {
		${'' /* color: #e7ab25; */}
		color: #ffffff;
		height: 50px;
		font-size: 36px;
		margin-right: 8;
		background-color: #ffffff22;
		border:none;
		display:flex;
		justify-content: center;
		align-items:center;
		right: ${({ right }) => right};
		left: ${({ left }) => left};
		&:hover{
			background:transparent;
		}
		&:disabled{
			background:transparent;
		}
		&:focus{
			background:transparent;
			border:none;
		}
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
`;