import styled from "styled-components";
import { Form } from "react-bootstrap";
import { colors } from "../../utils/theme";
export const FormContainer = styled(Form)`
  & {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${colors.brown};
  }
`;
export const SubmitButton = styled.div`
  width: fit-content;
  background: #e7ab25;
  padding: 1% 4%;
  font-size: 18px;
  background-color: ${colors.accent};
  color: ${colors.white};
  border-radius: 100px;
`;
