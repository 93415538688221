import React from "react";
import {
  ItemIcon,
  IconContainer,
  Label,
  LabelContainer,
  HorizontalSpacer,
  ListItemContainer,
  PointContainer,
  BulletPoint,
} from "./listItemStyle";
import iconUrl from "../../../assets/bullet_point.svg";
export default function ListItem({
  fontSize,
  iconRadius,
  iconBackground,
  containerHeight,
  containerWidth,
  itemLabel,
  iconHeight,
  iconWidth,
  fontWeight,
  labelColor,
  itemSpacing,
  itemMargin,
  blurBackground,
}) {
  return (
    <React.Fragment>
      <ListItemContainer
        fontSize={fontSize}
        fontWeight={fontWeight}
        labelColor={labelColor}
        itemMargin={itemMargin}
      >
        {/* <IconContainer
          iconRadius={iconRadius}
          blurBackground={blurBackground}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
        >
          <ItemIcon
            iconBackground={iconBackground}
            iconHeight={iconHeight}
            iconWidth={iconWidth}
          />
        </IconContainer> */}
        <PointContainer>
          <BulletPoint height={"24px"} width={"24px"} src={iconUrl} />
        </PointContainer>

        {/* <ItemIcon
          src={iconUrl}
          //   iconPadding={iconPadding}
          //   iconSize={iconSize}
          iconRadius={iconRadius}
          iconBackground={iconBackground}
        /> */}
        <HorizontalSpacer itemSpacing={itemSpacing} />
        <LabelContainer>
          <Label>{itemLabel}</Label>
        </LabelContainer>
      </ListItemContainer>
    </React.Fragment>
  );
}
