import React, {useRef} from "react";
import {Route, Navigate, Routes, useLocation} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AppBar from "./componentsRepo/navBar/appBar";
import Footer from "./mainComponents/footer/footer";
import HomePage from "./mainComponents/screens/homepage/homepage";
import SolarPanels from "./mainComponents/screens/solarPanels/solarPanels";
import ContactUs from "./mainComponents/screens/contactUs/contactsUs";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Villas from "./mainComponents/screens/villas/villasScreen";
import Apartments from "./mainComponents/screens/apartments/apartments";
import FloatingActionButton from './componentsRepo/floatingActionButton/floatingActionButton';
import logoMMV from './assets/logo-mmv.png'
import logoMMB from './assets/logo-mmb.png'
import NotFound from "./mainComponents/screens/notFound/notFound";
import StickyContact from "./componentsRepo/stickyContact/stickyContact";

function App() {
    return (
        <div className="App">
            <ToastContainer toastClassName="color"/>
            <AppBar position={
                    useLocation().pathname === "/home" ? "static" : "static"
                }
                logo={
                    useLocation().pathname === "/villas" ? logoMMV : logoMMB
                }/>
            <Routes>
                <Route path="/home"
                    element={
                        < HomePage />
                    }/>
                <Route path="/villas"
                    element={<Villas/>}/>
                <Route path="/homes"
                    element={<Apartments/>}/>
                <Route path="/contact"
                    element={<ContactUs/>}/>
                <Route path="/404"
                    element={<NotFound/>}/>
                <Route path="/"
                    element={
                        <Navigate
                    to="/home"/>
                    }/>
                <Route path="*"
                    element={
                        <Navigate
                    to="/home"/>
                    }/>
            </Routes>
            <FloatingActionButton/>
            {/* <StickyContact/> */}
            <Footer logo={
                useLocation().pathname === "/villas" ? logoMMV : logoMMB
            }/>
        </div>
    );
}

export default App;
