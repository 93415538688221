import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBzFmRyFvsEr5NUSON7_didcMDp9Chq6yM",
  authDomain: "master-homes-mmb.firebaseapp.com",
  projectId: "master-homes-mmb",
  storageBucket: "master-homes-mmb.appspot.com",
  messagingSenderId: "256837156422",
  appId: "1:256837156422:web:41dc2c5dc8a04041bad447",
  measurementId: "G-38T9PTRB68",
};
firebase.initializeApp(firebaseConfig);
export default firebase;
