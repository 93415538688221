import styled from "styled-components";
import { colors } from "../../utils/theme";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${colors.brown};
  ${"" /* background:red; */}
  font-size: 32px;
  padding: 2% 0 1% 0;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 2% 0 4% 0;
    font-size: 32px;
  }
  @media screen and (max-width: 540px) {
    padding: 4% 0 4% 0;
    font-size: 20px;
  }
`;

export const HeaderContainerImage = styled.img`
  width: 60px;
  -webkit-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  -moz-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  -ms-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  -o-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  @media screen and (max-width: 768px) {
    width: 65px;
    font-size: 38px;
  }
  @media screen and (max-width: 540px) {
    width: 50px;
    font-size: 32px;
  }
`;

export const VerticalSpacer = styled.div`
  margin: 8px 0;
`;
export const HorizontalSpacer = styled.div`
  margin: 0 8px;
`;
