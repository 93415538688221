import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  SideBarContainer,
  SideBarMenu,
  SideBarLink,
  IconButton,
  IconButtonText,
  ScrollLink,
} from "./sideBarStyle";

export default function SideBar({ data, isOpen, toggle }) {
  const history = useNavigate();
  const location = useLocation();
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
  });

  const navigateToHome = () => {
    if (location.pathname !== "/home") {
      history("/home");
    }
    toggle();
  };

  return (
    <>
      <SideBarContainer isOpen={isOpen}>
        <SideBarMenu>
          {data.NavBarData.map((item, index) => {
            if (item.path === "/about") {
              return (
                <ScrollLink
                  key={index}
                  to="about"
                  onClick={() => navigateToHome()}
                >
                  {item.title}
                </ScrollLink>
              );
            }
            return (
              <SideBarLink
                activeStyle={{
                  textDecoration: "underline",
                  textUnderlineOffset: "4px",
                  textDecorationColor: "#E9B237",
                  textDecorationThickness: "2px",
                }}
                to={item.path}
                onClick={toggle}
                key={item.title}
              >
                {item.title}
              </SideBarLink>
            );
          })}
          <IconButton to={"/contact"} onClick={toggle}>
            <IconButtonText>Contact Us</IconButtonText>
          </IconButton>
        </SideBarMenu>
      </SideBarContainer>
    </>
  );
}
