import React, { useEffect, useState } from "react";
import {
  BrandNameText,
  ContactDetailsDescription,
  ContactDetailsParent,
  ContactDetailsTitle,
  CopyrightContainer,
  CopyrightText,
  FooterParent,
  HorizontalSpacer,
  Logo,
  NavigationLinks,
  Navigation,
  NavigationParent,
  NavigationTitle,
  SocialIcon,
  SocialMenuContainer,
  SocialParent,
  VerticalSpacer,
  CompanyNameText,
  DetailsSection,
  ContactDetailsLink,
  CompanyNameTitle,
} from "./footerStyle";
import {
  FaInstagram,
  FaFacebookSquare,
  FaLinkedin,
  FaYoutube,
  FaTwitter,
} from "react-icons/fa";
import { getDeveloperDetails } from "../../services/firebaseService";

function SocialSection({ data, logo }) {
  const urlRedirect = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <SocialParent>
      {/* <VerticalSpacer /> */}
      <Logo src={logo} />
      <VerticalSpacer />
      <SocialMenuContainer>
        <SocialIcon>
          <FaInstagram
            onClick={() =>
              urlRedirect("https://www.instagram.com/master.homes.nakuru/")
            }
          />
        </SocialIcon>
        <HorizontalSpacer />
        <SocialIcon>
          <FaTwitter
            onClick={() => urlRedirect("https://twitter.com/MasterHomes_")}
          />
        </SocialIcon>
        <HorizontalSpacer />
        <SocialIcon>
          <FaYoutube
            onClick={() =>
              urlRedirect(
                "https://www.youtube.com/channel/UCRxvGYCtDZ1LD_pEo1Qt_HQ"
              )
            }
          />
        </SocialIcon>
      </SocialMenuContainer>
      <VerticalSpacer />
      <CopyrightContainer>
        <CopyrightText>Copyright © {new Date().getFullYear()}</CopyrightText>
        <br />
        <BrandNameText>Master Homes®</BrandNameText>
        <VerticalSpacer />
      </CopyrightContainer>
      <VerticalSpacer />
      <CompanyNameTitle>Designed & Developed by:</CompanyNameTitle>
      <CompanyNameText onClick={() => urlRedirect(data[0].link)}>
        {data[0] !== undefined ? data[0].label : " "}
      </CompanyNameText>
    </SocialParent>
  );
}

function NavigationSection({ data }) {
  return (
    <>
      {data.map((item, index) => {
        return (
          <NavigationParent key={item.title}>
            <NavigationTitle>{item.title}</NavigationTitle>
            {/* <VerticalSpacer /> */}
            {item.links.map((subItems, index) => {
              return (
                <div key={subItems.title}>
                  <NavigationLinks to={subItems.to}>
                    {subItems.title}
                  </NavigationLinks>
                  <VerticalSpacer />
                </div>
              );
            })}
            <VerticalSpacer />
          </NavigationParent>
        );
      })}
    </>
  );
}
function ContactDetails() {
  return (
    <ContactDetailsParent>
      <div>
        <ContactDetailsTitle>Contact:</ContactDetailsTitle>
        <ContactDetailsDescription>
          <ContactDetailsLink href="tel:+254 711 716 919">
            +254 711 716 919
          </ContactDetailsLink>
        </ContactDetailsDescription>
        <ContactDetailsDescription>
          <ContactDetailsLink href="tel:+254 748 406 246">
            +254 748 406 246
          </ContactDetailsLink>
        </ContactDetailsDescription>
        <ContactDetailsDescription>
          <ContactDetailsLink href="mailto:masterhomes.mmb@gmail.com">
            masterhomes.mmb@gmail.com
          </ContactDetailsLink>
        </ContactDetailsDescription>
      </div>
      <VerticalSpacer />
      <VerticalSpacer />
      <div>
        <ContactDetailsTitle>Address:</ContactDetailsTitle>
        <ContactDetailsDescription>
          Master & Major Builders, Ltd.
          <br />
          Nakuru, Kenya.
        </ContactDetailsDescription>
      </div>
      <VerticalSpacer />
    </ContactDetailsParent>
  );
}
export default function Footer({ logo }) {
  const data = [
    {
      title: "Projects",
      links: [
        {
          title: "Master Villas",
          to: "/villas",
        },
        {
          title: "Master Homes",
          to: "/homes",
        },
      ],
    },
  ];
  const [developerData, setDeveloperDataState] = useState([]);
  const setDeveloperData = async () => {
    const data = await getDeveloperDetails();
    setDeveloperDataState(data);
  };
  useEffect(() => {
    setDeveloperData();
  }, []);
  return (
    <FooterParent>
      <SocialSection data={developerData} logo={logo} />
      <DetailsSection>
        <Navigation>
          <NavigationSection data={data} />
        </Navigation>
        <ContactDetails />
      </DetailsSection>
    </FooterParent>
  );
}
