import React from "react";
// import brokenImage from "../../../assets/broken_wall.jpeg";

export default function NotFound() {
  return (
    <React.Fragment>
      <div>404 Not Found</div>
    </React.Fragment>
  );
}
