import React from "react";
import {
	ContactBannerContainer,
	ContactButton,
	ContactText,
    ContactButtonText,
    HorizontalSpacer,
	VerticalSpacer
} from "./contactBannerStyle";
import { FiArrowRight } from 'react-icons/fi';

export default function ContactBanner({title}) {
	return (
		<ContactBannerContainer>
			<ContactText>{title}</ContactText>
			<VerticalSpacer/>
			<ContactButton to="/contact">
				<ContactButtonText>Inquire Now </ContactButtonText>
                <HorizontalSpacer/>
				<FiArrowRight/>
			</ContactButton>
		</ContactBannerContainer>
	);
}
