import React from "react";
import GalleryCard from "./galleryCard/galleryCard";
import GalleryView from "./../../componentsRepo/galleryComponent/galleryComponent";
import {
  Header,
  HeaderContainerImage,
  HorizontalSpacer,
  VerticalSpacer,
} from "./gallerySectionStyle";
import arrowImage from "../../assets/arrow.svg";
export default function GallerySection({ data }) {
  const cardComponents = [];
  data.map((item, index) => {
    cardComponents.push(<GalleryCard item={item} id={index} />);
    return null;
  });
  return (
    <div style={{ backgroundColor: "white" }}>
      <VerticalSpacer />
      <VerticalSpacer />
      <Header>
        <HeaderContainerImage src={arrowImage} rotate={"rotate(0deg)"} />
        <HorizontalSpacer />
        Gallery
        <HorizontalSpacer />
        <HeaderContainerImage src={arrowImage} rotate={"rotate(180deg)"} />
      </Header>
      <GalleryView data={data} cardComponents={cardComponents} />
    </div>
  );
}
