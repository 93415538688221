import styled from "styled-components";
import { color } from "../../utils/theme";

export const InlineButtonParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  color: #715120;
  justify-content:flex-start;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  }
  @media screen and (max-width: 540px) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  }
`;
export const InlineButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 540px) {
    justify-content: flex-start;
    padding: 0;
  }
`;
export const ActionButtonParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ buttonColor }) =>
    buttonColor ? buttonColor : "#f4e8d7"};
  border-radius: 1000px;
  padding: ${({ padding }) => (padding ? padding : "8px 22px")};
  justify-content: center;
  color: ${({ textColor }) => (textColor ? textColor : "#715120")};
  cursor: pointer;
  margin: 12px;
  @media screen and (max-width: 768px) {
    padding: 8px 22px;
    width: 100%;
  }
  @media screen and (max-width: 540px) {
    padding: 8px 24px;
  }
`;
export const ActionButtonImage = styled.img`
  filter: ${({ isColoredIcon }) =>
    isColoredIcon
      ? "invert(30%) sepia(61%) saturate(493%) hue-rotate(357deg) brightness(91%) contrast(91%)"
      : "invert(100%) sepia(7%) saturate(61%) hue-rotate(34deg) brightness(118%) contrast(100%)"};
  width: ${({ width }) => (width ? width + "px" : "24px")};
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 540px) {
    width: ${({ width }) => (width ? width * 0.8 + "px" : "24px")};
  }
`;
export const ActionButtonTitle = styled.div``;
export const VerticalSpacer = styled.span`
  margin: ${({ spacerHeight }) => (spacerHeight ? spacerHeight : "0px")} 0 0 0;
`;

export const HorizontalSpacer = styled.div`
  margin: 0 ${({ spacerWidth }) => (spacerWidth ? spacerWidth : "0px")} 0 0;
`;
