import React from "react";
import Banner from "./bannerComponent/banner";
import Carousel from "../../componentsRepo/carousel/carousel";
export default function BannerSection({ data }) {
  const children = [];
  data.map((item, index) => {
    children.push(<Banner item={item} key={index} />);
    return null;
  });
  return (
    <Carousel
      children={children}
      showNavigationArrows={true}
      backgroundColor={"#DEF2F100"}
      carouselIndicatorColor={"#3d8696"}
      carouselIndicator={true}
    />
  );
}
