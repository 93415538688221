import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const GalleryGrid = styled(Grid)`
	&& {
		display: grid;
		grid-column-gap: 1%;
		grid-row-gap: 1%;
		padding: 1% 2% 6% 2%;
		max-width: 100%;
		height:100%;
		grid-template-columns: repeat(6, 1fr);
		@media screen and (max-width: 768px) {
			grid-template-columns: repeat(5, 1fr);
		}
		@media screen and (max-width: 540px) {
			grid-column-gap: 3%;
			grid-template-columns: repeat(3, 1fr);
			padding: 1% 2% 52% 2%;
		}
	}
`;
