import styled from "styled-components";
import { colors, fonts } from "../../../utils/theme";

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  background-image: linear-gradient(
    to right,
    ${({ leftColor }) => (leftColor ? leftColor : colors.primaryLight)},
    ${({ rightColor }) => (rightColor ? rightColor : colors.primaryDark)}
  );
  padding: 0 8% 4% 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 768px) {
    align-items: flex-center;
  }
`;

export const Heading = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize + "px" : "48px")};
  color: ${colors.brown};
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    text-align: ceter;
  }
  @media screen and (max-width: 540px) {
    text-align: center;
    font-size: ${({ fontSize }) => (fontSize ? fontSize * 0.6 + "px" : "32px")};
  }
`;
export const Desrciption = styled.div`
  font-size: 20px;
  color: white;
  font-family: ${fonts.garamond};
  color: ${colors.brown};
  @media screen and (max-width: 768px) {
    font-size: 18px;
    text-align: center;
  }
  @media screen and (max-width: 540px) {
    // text-align: left;
    font-size: 18px;
  }
`;

export const VideoContainer = styled.div`
  border-radius: 50px;
  width: 1000px;
  height: 480px;
  padding: 10px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 500px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 500px;
  }
  @media screen and (max-width: 540px) {
    width: 100%;
    height: 360px;
  }
`;

export const ListColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: ${fonts.garamond};
  height: fit-content;
  @media screen and (max-width: 768px) {
    align-items: center;
  }
  @media screen and (max-width: 540px) {
    align-items: flex-start;
  }
`;

export const VerticalSpacer = styled.span`
  height: 32px;
  @media screen and (max-width: 768px) {
    height: 24px;
  }
  @media screen and (max-width: 540px) {
    height: 12px;
  }
`;

export const HorizontalSpacer = styled.div`
  margin: 0 8px;
`;

export const HeaderContainerImage = styled.img`
  width: ${({ width }) => (width ? width : "80px")};
  -webkit-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  -moz-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  -ms-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  -o-transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  transform: ${({ rotate }) => (rotate ? rotate : "rotate(180deg)")};
  @media screen and (max-width: 768px) {
    width: 65px;
    font-size: 38px;
  }
  @media screen and (max-width: 540px) {
    width: 50px;
  }
`;

export const DownloadBrocherButton = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.accent};
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  border-radius: 100px;
  padding: 16px 24px;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 540px) {
    margin: 24px 0;
  }
`;
export const DownloadBrocherButtonText = styled.div`
  font-size: 18px;
`;
export const DownloadBrocherButtonIcon = styled.img`
  width: 24px;
  filter: invert(100%) sepia(0%) saturate(7443%) hue-rotate(159deg)
    brightness(110%) contrast(99%);
`;
