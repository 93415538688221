import React from "react";
import { ParentContainer } from "./gridViewStyle";

export default function GridView({
	desktopColumns,
	tabletColumns,
	phoneColumns,
	children,
	padding,
	direction,
}) {
	return (
		<ParentContainer
			padding={padding}
			direction={direction}
			desktopColumns={100 / desktopColumns + "%"}
			tabletColumns={100 / tabletColumns + "%"}
			phoneColumns={100 / phoneColumns + "%"}
		>
			{children.map((child) => {
				return child;
			})}
		</ParentContainer>
	);
}
