import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { colors } from "../../utils/theme";
export const SideBarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  padding: 4%;
  background-image: linear-gradient(
    to right,
    ${colors.primaryLight},
    ${colors.primaryDark}
  );
  right: ${({ isOpen }) => (isOpen ? "-0" : "-100%")};
  transition: 0.4s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
`;

export const SidebarLabel = styled.span`
  margin-left: 16px;
  color: black;
`;

export const SideBarMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: ${colors.brown};
  ${"" /* background:yellow; */}
  align-items:center;
  height: 50%;
`;

export const SideBarLink = styled(NavLink)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 28px;
    font-weight: 400;
    width: fit-content;
    transition: 0.2s ease-in-out;
    color: ${colors.brown};
    cursor: pointer;
    @media screen and (max-width: 420px) {
      font-size: 22px;
    }
  }
`;
export const ScrollLink = styled(Link)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 28px;
    font-weight: 400;
    width: fit-content;
    transition: 0.2s ease-in-out;
    color: ${colors.brown};
    cursor: pointer;
    @media screen and (max-width: 420px) {
      font-size: 22px;
    }
  }
`;

export const IconButton = styled(NavLink)`
  & {
    padding: 8px 16px;
    width: fit-content;
    background-color: ${colors.accent};
    font-size: 14px;
    display: flex;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 100px;
    color: ${colors.white};
    @media screen and (max-width: 540px) {
      font-size: 20px;
    }
  }
`;
export const IconButtonText = styled.p`
  margin: auto;
  font-size: inherit;
  text-transform: uppercase;
`;
