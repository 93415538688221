import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  NavBarContainer,
  NavbarLink,
  NavMenu,
  MenuToggleIcon,
  Logo,
  IconButton,
  IconButtonText,
  ScrollLink,
} from "./navBarStyle";
import { FiMenu, FiX } from "react-icons/fi";
import { colors } from "../../utils/theme";

const NavBar = ({ links, toggle, isOpen, position, logo }) => {
  const history = useNavigate();
  const location = useLocation();
  const logoClick = (path) => {
    history(path);
  };

  const navigateToHome = () => {
    if (location.pathname !== "/home") {
      history("/home");
    }
  };

  return (
    <NavBarContainer bgColor={"#114246"} position={position}>
      <Logo onClick={() => logoClick("/home")} src={logo} alt="logo" />
      <MenuToggleIcon>
        {!isOpen ? (
          <FiMenu className={"menuToggleIcon"} onClick={toggle} />
        ) : (
          <FiX className="menuToggleIcon" onClick={toggle} />
        )}
      </MenuToggleIcon>
      <NavMenu>
        {links.NavBarData.map((item, index) => {
          if (item.path === "/about") {
            return (
              <ScrollLink
                onClick={() => navigateToHome()}
                key={index}
                to="about"
              >
                {item.title}
              </ScrollLink>
            );
          }
          return (
            <NavbarLink
              activeStyle={{
                textDecoration: "underline",
                textUnderlineOffset: "4px",
                textDecorationColor: "#E9B237",
                textDecorationThickness: "2px",
              }}
              to={item.path}
              key={index}
            >
              {item.title}
            </NavbarLink>
          );
        })}
        <IconButton to={"/contact"}>
          <IconButtonText>Contact Us</IconButtonText>
        </IconButton>
      </NavMenu>
    </NavBarContainer>
  );
};

export default NavBar;
