import React from "react";
import ListItem from "./listItem/listItem";
import {
  ListHeader,
  ListParent,
  ListParentContiner,
} from "./listComponentStyle";

export default function ListComponent({
  header,
  margin,
  listData,
  itemObject,
  alignment,
}) {
  return (
    <ListParent alignment={alignment} margin={margin}>
      <ListParentContiner>
        <ListHeader>{header}</ListHeader>
        {listData.map((item, index) => {
          return (
            <ListItem
              {...itemObject}
              key={index}
              itemLabel={item.title}
              iconUrl={item.iconUrl}
            />
          );
        })}
      </ListParentContiner>
    </ListParent>
  );
}
