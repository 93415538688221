import React from "react";
import whatsapp from "../../assets/whatsapp.png";
import instgram from "../../assets/instagram.png";
import {FABImg, FABRoot} from "./floatingActionButtonStyle";

function FloatingActionButton() {
    const handleAppOpen = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) 
            newWindow.opener = null;
        


    };
    const message = "Hello Sir";
    return (<div>
        <FABRoot onClick={
                () => handleAppOpen(`https://www.instagram.com/master.homes.nakuru/`)
            }
            left={"32px"}
            className="root"
            padding={"2px"}>
            <FABImg src={instgram}
                alt="whatsapp"
                className="img"/>
        </FABRoot>
        <FABRoot onClick={
                () => handleAppOpen(`https://api.whatsapp.com/send?phone=254748406246&text=${message}`)
            }
            right={"32px"}
            padding={"6px"}
            className="root">
            <FABImg src={whatsapp}
                alt="whatsapp"
                className="img"/>
        </FABRoot>
    </div>);
}
export default FloatingActionButton;
