import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors, fonts } from "../../utils/theme";

export const SocialParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1.5;
`;

export const Logo = styled.img`
  width: 18%;
  @media screen and (max-width: 768px) {
    width: 40%;
  }
  @media screen and (max-width: 540px) {
    width: 38%;
  }
  @media screen and (max-width: 500px) {
    width: 32%;
  }
`;

export const SocialMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 768px) {
    align-items: baseline;
    flex-direction: row;
    display: flex;
  }
`;

export const SocialIcon = styled.div`
  font-size: 24px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const CopyrightContainer = styled.div`
  font-size: 16px;
`;

export const CopyrightText = styled.span`
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const BrandNameText = styled.span`
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
export const CompanyNameText = styled.span`
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 1.3px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  &:hover {
    color: ${colors.accentDark};
  }
`;
export const CompanyNameTitle = styled.span`
  font-size: 14px;
  letter-spacing: 1.3px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
export const ContactDetailsParent = styled.div`
  display: flex;
  ${"" /* background:red; */}
  flex-direction: column;
  justify-content: space-between;
  align-item: flex-start;
  padding: 32px 0;
  @media screen and (max-width: 768px) {
    flex: 1;
  }
`;
export const ContactDetailsTitle = styled.div`
  font-size: 24px;
  margin-bottom: 4px;
  font-weight: 500;
`;
export const ContactDetailsDescription = styled.div`
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  font-family: ${fonts.garamond};
`;
export const ContactDetailsLink = styled.a`
  text-decoration: none;
  color: ${colors.white};
  &:hover {
    color: ${colors.accentDark};
  }
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding: 32px 0;
  ${"" /* background:yellow; */} @media screen and
    (max-width: 768px) {
    font-size: 14px;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
`;
export const NavigationParent = styled.div`
  display: flex;
  flex-direction: column;
`;
export const NavigationTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
`;
export const NavigationLinks = styled(Link)`
  & {
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    color: ${colors.white};
    &:hover {
      color: ${colors.accentDark};
    }
  }
`;
export const FooterParent = styled.div`
  display: flex;
  background-image: linear-gradient(
    to right,
    ${colors.accentLight},
    ${colors.accentLight}
  );
  color: ${colors.white};
  flex-direction: row;
  justify-content: space-between;
  padding: 0 72px 72px 72px;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    padding: 0 24px 24px 24px;
  }
  @media screen and (max-width: 540px) {
    flex-direction: column-reverse;
    padding: 24px;
  }
`;

export const VerticalSpacer = styled.div`
  margin: ${({ margin }) => (margin ? margin : "8px 0")};
`;
export const HorizontalSpacer = styled.div`
  margin: 0 8px;
`;
export const DetailsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex: 1;
  @media screen and (max-width: 900px) {
    flex-direction: row;
    flex: 2;
  }
  @media screen and (max-width: 540px) {
    flex-direction: column;
  }
`;
