import React from "react";
import { isStringValid } from "../../utils/utils";
import {
  Button,
  CardContainer,
  DescriptionContainer,
  HorizontalSpacer,
  ImageConatiner,
  TagContainer,
  TagContainerImage,
  TitleContainer,
  VerticalSpacer,
} from "./contentCardStyle";
import arrowImage from "../../assets/arrow.svg";
import InlineButton from "../inlineButton/inlineButton";

function CardComponent({
  cardPadding,
  bgColor,
  gradient,
  imageLink,
  alignItems,
  imageHeight,
  imageWidth,
  tagText,
  tagTextSize,
  tagTextColor,
  tagFontWeight,
  titleText,
  titleTextSize,
  titleTextColor,
  titleFontWeight,
  descriptionText,
  descriptionTextSize,
  descriptionTextColor,
  descriptionFontWeight,
  buttonTextColor,
  buttonTextSize,
  buttonFontWeight,
  buttonFillColor,
  buttonRadius,
  buttonBorderStyle,
  buttonPadding,
  buttonText,
  onCallback,
  desktopWeight, //Only when using flex view
  tabletWeight, //Only when using flex view
  phoneWeight,
  itemSpacing, //Only when using flex view
  hoverStyle,
  boxShadow,
  showTagImage = false,
  tagImageWidth,
  showMoreOptions = false,
  isBlinkingEnabled = false,
  child,
}) {
  const onClick = () => {
    onCallback();
  };
  return (
    <React.Fragment>
      <CardContainer
        cardPadding={cardPadding}
        bgColor={bgColor}
        gradient={gradient}
        alignItems={alignItems}
        hoverStyle={hoverStyle}
        boxShadow={boxShadow}
        desktopWeight={100 / desktopWeight + "%"}
        tabletWeight={100 / tabletWeight + "%"}
        phoneWeight={100 / phoneWeight + "%"}
        key={tagText}
      >
        {isStringValid(imageLink) ? (
          <ImageConatiner
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            src={imageLink}
          />
        ) : null}
        <VerticalSpacer spacerHeight={itemSpacing} />
        {isStringValid(tagText) ? (
          <TagContainer
            tagTextSize={tagTextSize}
            tagTextColor={tagTextColor}
            tagFontWeight={tagFontWeight}
          >
            {showTagImage !== false ? (
              <TagContainerImage
                src={arrowImage}
                rotate={"rotate(0deg)"}
                width={tagImageWidth}
              />
            ) : null}
            {showTagImage !== false ? <HorizontalSpacer /> : null}
            {tagText}
            {showTagImage !== false ? <HorizontalSpacer /> : null}
            {showTagImage !== false ? (
              <TagContainerImage
                src={arrowImage}
                rotate={"rotate(180deg)"}
                width={tagImageWidth}
              />
            ) : null}
          </TagContainer>
        ) : null}
        <VerticalSpacer spacerHeight={itemSpacing} />
        {isStringValid(titleText) ? (
          <TitleContainer
            titleTextSize={titleTextSize}
            titleTextColor={titleTextColor}
            titleFontWeight={titleFontWeight}
          >
            {titleText}
          </TitleContainer>
        ) : null}
        <VerticalSpacer spacerHeight={itemSpacing} />
        {isStringValid(descriptionText)
          ? descriptionText.split("\n").map((str) => (
              <DescriptionContainer
                descriptionTextSize={descriptionTextSize}
                descriptionTextColor={descriptionTextColor}
                descriptionFontWeight={descriptionFontWeight}
                key={str}
              >
                {str}
              </DescriptionContainer>
            ))
          : null}
        <VerticalSpacer spacerHeight={itemSpacing} />
        {isStringValid(buttonText) ? (
          <Button
            isBlinkingEnabled={isBlinkingEnabled}
            buttonTextColor={buttonTextColor}
            buttonTextSize={buttonTextSize}
            buttonFontWeight={buttonFontWeight}
            buttonFillColor={buttonFillColor}
            buttonRadius={buttonRadius}
            buttonBorderStyle={buttonBorderStyle}
            buttonPadding={buttonPadding}
            onClick={() => onClick()}
          >
            {buttonText}
          </Button>
        ) : null}
        {showMoreOptions ? <VerticalSpacer spacerHeight={"4%"} /> : null}
        {showMoreOptions ? <InlineButton padding={"12px 28px"} /> : null}
      </CardContainer>
    </React.Fragment>
  );
}

export default CardComponent;
