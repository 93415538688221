import styled from "styled-components";

export const ImageCarouselRoot = styled.div`
	display: flex;
	padding: 16px 0;
	flex-direction: column;
	align-items: flex-end;
	z-index: 999;
	height: 100%;
	width: 100%;
	top: 0;
	justify-content: center;
	position: fixed;
	overflow-y: hidden;
	background: #000000dd;
`;
export const ImageContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100vh;
	${'' /* background: red; */}
	width: 100auto;
	justify-content: center;
`;
export const Image = styled.img`
	max-width: 60%;
	max-height: 100%;
	padding: 4px;
	${'' /* background: yellow; */}
	object-fit: cover;
	@media screen and (max-width: 768px) {
		max-width: 70%;
	}
	@media screen and (max-width: 420px) {
		max-width: 80%;
	}
`;
