import React, { useEffect, useState } from "react";
import GallerySection from "../../gallery/gallerySection";
import icon from "../../../assets/quote.png";
import downloadImage from "../../../assets/download.svg";
import ContactBanner from "../../contactBanner/contactBanner";
import { getInteriorGalleryImages } from "../../../services/firebaseService";
import {
  Desrciption,
  DownloadBrocherButton,
  DownloadBrocherButtonIcon,
  DownloadBrocherButtonText,
  HeaderContainer,
  HeaderContainerImage,
  Heading,
  HorizontalSpacer,
  VerticalSpacer,
  VideoContainer,
} from "./villasScreenStyle";
import GridView from "../../../componentsRepo/gridViewComponent/gridView";
import ListComponent from "../../../componentsRepo/listComponent/listComponent";
import { colors } from "../../../utils/theme";
import arrowImage from "../../../assets/arrow.svg";

export default function Villas() {
  const [interiorGalleryImages, setInteriorGalleryImagesState] = useState([]);
  const setInteriorGalleryImages = async () => {
    const images = await getInteriorGalleryImages();
    setInteriorGalleryImagesState(images);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setInteriorGalleryImages();
  }, []);

  const openBrochuer = (url) => {
    window.open(url);
  };
  const listDataColumnsKeyFeatures = [
    {
      title: "Best possible construction quality",
      iconUrl: icon,
    },
    {
      title: "Specially designed roofs, for optimum water harvesting.",
      iconUrl: icon,
    },
    {
      title: "Water proofed houses from foundation.",
      iconUrl: icon,
    },
    {
      title: "Watchman cabin with his attached toilet.",
      iconUrl: icon,
    },
    {
      title: "3 cars parking space & individual landscaped garden space.",
      iconUrl: icon,
    },
    {
      title:
        "10000 ltrs underground water tanks, pump house & 2 overhead tanks.",
      iconUrl: icon,
    },
  ];
  const listDataColumnsKeyLocations = [
    {
      title: "1.6 km Kiroban High School",
      iconUrl: icon,
    },
    {
      title: "2.4 km Trinity Academy Primary School",
      iconUrl: icon,
    },
    {
      title: "1.2 km Lake Oil Petrol Station",
      iconUrl: icon,
    },
    {
      title: "1.3 km Alpacino's Garden Lounge Grill",
      iconUrl: icon,
    },
    {
      title: "1.4 km Ganglong International Company",
      iconUrl: icon,
    },
  ];
  const listDataColumns1 = [
    {
      title: "'A' class construction",
      iconUrl: icon,
    },
    {
      title: "Rain Water Harvesting",
      iconUrl: icon,
    },
    {
      title: "Solar powered parking area lights",
      iconUrl: icon,
    },
    {
      title: "Exterior grade acrylic paints",
      iconUrl: icon,
    },
    {
      title: "Premier landscaped gardens",
      iconUrl: icon,
    },
    {
      title: "Open-to-Sky common space",
      iconUrl: icon,
    },
  ];
  const listDataColumns2 = [
    {
      title: "Vitrified tiles in living, kitchen & bedrooms",
      iconUrl: icon,
    },
    {
      title: "Matt finish ceramic flooring in toilets",
      iconUrl: icon,
    },
    {
      title: "Gypsum punning on walls",
      iconUrl: icon,
    },
    {
      title: "OBD paints for walls & ceilings",
      iconUrl: icon,
    },
  ];
  const listDataColumns3 = [
    {
      title: "Laminated flush doors with SS fitting in bedrooms",
      iconUrl: icon,
    },
    {
      title: "Water resistant door shutters, with SS fitting for toilets",
      iconUrl: icon,
    },
    {
      title: "Metal sturdy big windows for ample light & air",
      iconUrl: icon,
    },
    {
      title: "Designer door fittings & locks",
      iconUrl: icon,
    },
  ];
  const listDataColumns4 = [
    {
      title: "Open kitchen counter",
      iconUrl: icon,
    },
    {
      title: "Stainless Steel sink",
      iconUrl: icon,
    },
    {
      title: "Glazed tile dado on kitchen platform",
      iconUrl: icon,
    },
    {
      title: "Provision for water purifier & exhaust fan",
      iconUrl: icon,
    },
    {
      title: "Granite kitchen top with fitted cupboards",
      iconUrl: icon,
    },
  ];
  const listDataColumns5 = [
    {
      title: "Concealed plumbing using PPR piping",
      iconUrl: icon,
    },
    {
      title: "White / colored ceramic sanitaryware",
      iconUrl: icon,
    },
    {
      title:
        "Single lever diverter in shower areas & single lever basin tap mixer for all toilets",
      iconUrl: icon,
    },
    {
      title: "Floor mounted WC units with flush",
      iconUrl: icon,
    },
  ];
  const listDataColumns6 = [
    {
      title: "24/7 CCTV surveillance",
      iconUrl: icon,
    },
    {
      title: "Gated perimeter wall with electric fencing",
      iconUrl: icon,
    },
    {
      title: "Reputed security service",
      iconUrl: icon,
    },
    {
      title: "Ample parking space",
      iconUrl: icon,
    },
    {
      title: "Cabro paved parking",
      iconUrl: icon,
    },
  ];
  const itemObject = {
    fontSize: 18,
    iconColor: "yellow",
    iconBackground: "#e7ab25",
    iconRadius: "100%",
    fontWeight: "500",
    labelColor: colors.brown,
    containerHeight: "24px",
    containerWidth: "24px",
    iconHeight: "12px",
    iconWidth: "12px",
    itemSpacing: "16px",
    itemMargin: "8px 0",
    blurBackground: "#e7ab2566",
  };
  const headerHeading = "Master Villas";
  const headerDescription =
    "Beautifully crafted 3 BHK villas in Nakuru. Kenya.\nOur Ngata site is strategically connecting on one to side to the famous Salga Industrial Area, and on the other to the newly developing road, directly connecting to Mercy Njeri. The villas are a few hundred meters off the Nakuru Kisumu Highway. In the immediate neighbourhood is the Kenya Police Cooperative plot area.";
  return (
    <React.Fragment>
      <HeaderContainer>
        <Heading>
          <HeaderContainerImage src={arrowImage} rotate={"rotate(0deg)"} />
          <HorizontalSpacer />
          {headerHeading}
          <HorizontalSpacer />
          <HeaderContainerImage src={arrowImage} rotate={"rotate(180deg)"} />
        </Heading>
        {headerDescription.split("\n").map((str) => (
          <Desrciption key={str}>{str}</Desrciption>
        ))}
        <VerticalSpacer />
        <Desrciption>
          Each villa is painted in different colors to accentuate the beauty
          with all rooms with ample ventilation and sunlight streaming in.{" "}
          <br />A perfect place to relax and rejuvenate.
        </Desrciption>
        <VerticalSpacer />
        <VideoContainer>
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/KsF2lpE7wF8?rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            style={{ borderRadius: "50px" }}
          />
        </VideoContainer>
        <VerticalSpacer />
        <DownloadBrocherButton
          onClick={() =>
            openBrochuer(
              "https://firebasestorage.googleapis.com/v0/b/master-homes-mmb.appspot.com/o/brochure%2FMASTER%20HOMES%20HIGH%20RISE.pdf?alt=media&token=d6c9ad0e-a1b2-415f-b37e-9b456d407e55"
            )
          }
        >
          <DownloadBrocherButtonIcon src={downloadImage} />
          <HorizontalSpacer />
          <DownloadBrocherButtonText>
            Download Brochure
          </DownloadBrocherButtonText>
        </DownloadBrocherButton>
        <VerticalSpacer />
        <GridView
          desktopColumns={3}
          tabletColumns={2}
          phoneColumns={1}
          children={[
            <ListComponent
              alignment={"center"}
              key={listDataColumnsKeyFeatures}
              margin={"28px 0"}
              listData={listDataColumnsKeyFeatures}
              itemObject={itemObject}
              header={"Key Features"}
            />,
            <ListComponent
              alignment={"center"}
              key={listDataColumnsKeyLocations}
              margin={"28px 0"}
              listData={listDataColumnsKeyLocations}
              itemObject={itemObject}
              header={"Strategic Location"}
            />,
          ]}
        />
        <VerticalSpacer />
        <VerticalSpacer />
        <Desrciption>
          Clear title deeds for all properties <br /> In the 1st phase of 8
          villas, customers have already purchased 2 in pre-booking.
        </Desrciption>
      </HeaderContainer>
      <GallerySection data={interiorGalleryImages} />
      <HeaderContainer leftColor={colors.white} rightColor={colors.white}>
        <VerticalSpacer />
        <Heading fontSize={32}>
          <HeaderContainerImage
            width={"60px"}
            src={arrowImage}
            rotate={"rotate(0deg)"}
          />
          <HorizontalSpacer />
          Salient Features
          <HorizontalSpacer />
          <HeaderContainerImage
            width={"60px"}
            src={arrowImage}
            rotate={"rotate(180deg)"}
          />
        </Heading>
        <VerticalSpacer />
        {/* <VideoContainer>
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/_cCrp8gDsVA`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            style={{ borderRadius: "50px" }}
          />
        </VideoContainer> */}
        {/* <VerticalSpacer />
        <VerticalSpacer /> */}
        <GridView
          desktopColumns={3}
          tabletColumns={2}
          phoneColumns={1}
          children={[
            <ListComponent
              key={listDataColumns1}
              margin={"28px 0"}
              listData={listDataColumns1}
              itemObject={itemObject}
              header={"Construction & Landscape"}
            />,
            <ListComponent
              key={listDataColumns2}
              margin={"28px 0"}
              listData={listDataColumns2}
              itemObject={itemObject}
              header={"Flooring, Walls & Ceiling"}
            />,
            <ListComponent
              key={listDataColumns3}
              margin={"28px 0"}
              listData={listDataColumns3}
              itemObject={itemObject}
              header={"Doors & Windows"}
            />,
            <ListComponent
              key={listDataColumns4}
              margin={"28px 0"}
              listData={listDataColumns4}
              itemObject={itemObject}
              header={"Kitchen"}
            />,
            <ListComponent
              key={listDataColumns5}
              margin={"28px 0"}
              listData={listDataColumns5}
              itemObject={itemObject}
              header={"Bathroom & Toilets"}
            />,
            <ListComponent
              key={listDataColumns6}
              margin={"28px 0"}
              listData={listDataColumns6}
              itemObject={itemObject}
              header={"Security & Parking"}
            />,
          ]}
        />
        <VerticalSpacer />
      </HeaderContainer>
      {/* <GallerySection data={interiorGalleryImages} /> */}
      <ContactBanner title={"Experience your dream home today"} />
    </React.Fragment>
  );
}
