import React from "react";
import { colors } from "../../utils/theme";
import { MapContainer } from "./mapComponentStyle";

export default function MapComponent() {
  var color = "18px solid" + colors.opaqueBrown;
  return (
    <MapContainer>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.776340357375!2d36.01547711460191!3d-0.26283463543967733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18298d356d9009c7%3A0x23936acee763f89!2sMaster%20Homes%20Apartments!5e0!3m2!1sen!2sin!4v1666434139799!5m2!1sen!2sin"
        width="100%"
        height="450"
        // style={{
        //   "webkit-border-radius": "1000px",
        //   "-moz-border-radius": "1000px",
        //   "border-radius": "10px",
        //   // border: color,
        // }}
        allowFullScreen={true}
        loading="lazy"
        title="Map"
      ></iframe>
    </MapContainer>
  );
}
