export const colors = {
  accent: "#3d8696",
  accentLight: "#33727f",
  accentDark: "#234d57",
  white: "#ffffff",
  brown: "#715120",
  opaqueBrown: "#71512018",
  black: "#160D08",
  primaryLight: "#f4e8d7",
  primaryDark: "#f4e8d7",
};

export const fonts = {
  garamond: "Garamond",
};
