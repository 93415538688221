import { BannerImage, BannerContainer } from "./bannerStyle";
const getImageUrl = (step) => {
  if (window.innerWidth >= 1200) {
    return step.imageUrl;
  } else if (window.innerWidth >= 540 && window.innerWidth < 1200) {
    return step.imageUrl;
  } else {
    return step.imageUrl;
  }
};
const Banner = ({ item }) => {
  return (
    <BannerContainer key={item.label}>
      <BannerImage
        objectPosition={item.position}
        src={getImageUrl(item)}
        alt=""
      />
    </BannerContainer>
  );
};

export default Banner;
