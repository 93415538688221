import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { colors } from "../../utils/theme";

export const ContactBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2% 4%;
  background: ${colors.primaryLight};
  @media screen and (max-width: 768px) {
    align-items: center;
    ${
      "" /* padding: 2% 4%;
		justify-content: flex-start; */
    }
  }
  @media screen and (max-width: 555px) {
    padding: 4% 4%;
    align-items: flex-start;
    flex-direction: column;
  }
`;
export const ContactText = styled.div`
  font-size: 38px;
  color: ${colors.brown};
  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }
  @media screen and (max-width: 786px) {
    font-size: 24px;
  }
  @media screen and (max-width: 555px) {
    font-size: 28px;
  } ;
`;
export const ContactButton = styled(NavLink)`
  & {
    background: ${colors.accent};
    text-decoration: none;
    padding: 1% 2%;
    font-size: 24px;
    color: ${colors.white};
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 100px;
    justify-content: space-between;
    &:hover {
      color: ${colors.accentDark};
    }
    @media screen and (max-width: 1024px) {
      // font-size: 18px;
      width: 28%;
    }
    @media screen and (max-width: 786px) {
      font-size: 18px;
      width: 28%;
    }
    @media screen and (max-width: 555px) {
      font-weight: 400;
      padding: 2% 4%;
      font-size: 24px;
      width: 72%;
    }
  }
`;
export const ContactButtonText = styled.div``;
export const HorizontalSpacer = styled.div`
  margin: 0 8px;
`;
export const VerticalSpacer = styled.div`
  margin: 8px 0px;
`;
