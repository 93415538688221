import styled from "styled-components";
import { colors } from "../../utils/theme";

export const MapContainer = styled.div`
  // padding: 0 2% 2% 2%;
  background-image: linear-gradient(
    to right,
    ${colors.primaryLight},
    ${colors.primaryDark}
  );
`;
