import React, { useState, useEffect } from "react";
import GallerySection from "../../gallery/gallerySection";
import icon from "../../../assets/quote.png";
import arrowImage from "../../../assets/arrow.svg";
import downloadImage from "../../../assets/download.svg";
import ContactBanner from "../../contactBanner/contactBanner";
import { getSteelFabricationGalleryImages } from "../../../services/firebaseService";
import GridView from "../../../componentsRepo/gridViewComponent/gridView";
import ListComponent from "../../../componentsRepo/listComponent/listComponent";
import {
  Desrciption,
  DownloadBrocherButton,
  DownloadBrocherButtonIcon,
  DownloadBrocherButtonText,
  HeaderContainer,
  HeaderContainerImage,
  Heading,
  HorizontalSpacer,
  VerticalSpacer,
  VideoContainer,
} from "./apartmentsStyle";
import { colors } from "../../../utils/theme";

export default function Apartments() {
  const [steelFabricationGalleryImages, setSteelFabricationGalleryImagesState] =
    useState([]);
  const setSteelFabricationGalleryImages = async () => {
    const images = await getSteelFabricationGalleryImages();
    setSteelFabricationGalleryImagesState(images);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setSteelFabricationGalleryImages();
  }, []);
  const openBrochuer = (url) => {
    window.open(url);
  };
  const strategicLocations1 = [
    {
      title: "0.1km Proposed Service Station",
      iconUrl: icon,
    },
    {
      title: "0.5km Royal Gardens",
      iconUrl: icon,
    },
    {
      title: "0.6km Zaburi Shopping Centre",
      iconUrl: icon,
    },
    {
      title: "1.2km Mustard Primary / Secondary School",
      iconUrl: icon,
    },
    {
      title: "1.3km MOI University College of Health Science.",
      iconUrl: icon,
    },
  ];
  const strategicLocations2 = [
    {
      title: "2km Nakuru Club",
      iconUrl: icon,
    },
    {
      title: "2.9km Nakuru-Kisumu Highway",
      iconUrl: icon,
    },
    {
      title: "4.5km Industrial Area",
      iconUrl: icon,
    },
    {
      title: "5km London",
      iconUrl: icon,
    },
    {
      title: "6km Milimani",
      iconUrl: icon,
    },
  ];
  const listDataColumn1 = [
    {
      title: "Steel Structures",
      iconUrl: icon,
    },
    {
      title: "Industrial Sheds",
      iconUrl: icon,
    },
    {
      title: "Mezanine Floors",
      iconUrl: icon,
    },
    {
      title: "Prefabricated Homes",
      iconUrl: icon,
    },
    {
      title: "Tubular / Angular Structures",
      iconUrl: icon,
    },
    {
      title: "High tech High rise Goat Farm",
      iconUrl: icon,
    },
  ];
  const listDataColumn2 = [
    {
      title: "Collapsible / Compound Gates",
      iconUrl: icon,
    },
    {
      title: "Metallic Home Doors",
      iconUrl: icon,
    },
    {
      title: "Steel Windows",
      iconUrl: icon,
    },
    {
      title: "Alluminium Sliding Windows",
      iconUrl: icon,
    },
  ];
  const listDataColumn3 = [
    {
      title: "Steel Cupboards",
      iconUrl: icon,
    },
    {
      title: "Chairs",
      iconUrl: icon,
    },
    {
      title: "Cots",
      iconUrl: icon,
    },
    {
      title: "Window Grills",
      iconUrl: icon,
    },
    {
      title: "Staircase & Railings",
      iconUrl: icon,
    },
  ];
  const listDataColumn4 = [
    {
      title: "Industrial / Textile / Pharmaceutical Trolleys",
      iconUrl: icon,
    },
    {
      title: "Rolling Shutters",
      iconUrl: icon,
    },
    {
      title: "Material Handling Systems",
      iconUrl: icon,
    },
    {
      title: "Centering Plates",
      iconUrl: icon,
    },
  ];
  const listDataColumns1 = [
    {
      title: "'A' class construction",
      iconUrl: icon,
    },
    {
      title: "Rain Water Harvesting",
      iconUrl: icon,
    },
    {
      title: "Solar powered parking area lights",
      iconUrl: icon,
    },
    {
      title: "Exterior grade acrylic paints",
      iconUrl: icon,
    },
    {
      title: "Premier landscaped gardens",
      iconUrl: icon,
    },
    {
      title: "Open-to-Sky common space",
      iconUrl: icon,
    },
    {
      title: "Compound borehole for 24/7 water availability",
      iconUrl: icon,
    },
  ];
  const listDataColumns2 = [
    {
      title: "Vitrified tiles in living, kitchen & bedrooms",
      iconUrl: icon,
    },
    {
      title: "Matt finish ceramic flooring in toilets",
      iconUrl: icon,
    },
    {
      title: "Antiskid tiles for balconies & staircase",
      iconUrl: icon,
    },
    {
      title: "Gypsum punning on walls",
      iconUrl: icon,
    },
    {
      title: "OBD paints for walls & ceilings",
      iconUrl: icon,
    },
  ];
  const listDataColumns3 = [
    {
      title: "Laminated flush doors with SS fitting for entrance & bedrooms",
      iconUrl: icon,
    },
    {
      title: "Water resistant door shutters, with SS fitting for toilets",
      iconUrl: icon,
    },
    {
      title: "Powder coated aluminium sliding windows / UPVC",
      iconUrl: icon,
    },
    {
      title: "MS railing for attached terraces",
      iconUrl: icon,
    },
    {
      title: "Designer door fittings & locks",
      iconUrl: icon,
    },
  ];
  const listDataColumns4 = [
    {
      title: "Open kitchen counter",
      iconUrl: icon,
    },
    {
      title: "Stainless Steel sink",
      iconUrl: icon,
    },
    {
      title: "Glazed tile dado on kitchen platform",
      iconUrl: icon,
    },
    {
      title: "Provision for water purifier & exhaust fan",
      iconUrl: icon,
    },
    {
      title: "Granite kitchen top with fitted cupboards",
      iconUrl: icon,
    },
    {
      title: "Dry balcony & separate store room",
      iconUrl: icon,
    },
  ];
  const listDataColumns5 = [
    {
      title: "Concealed plumbing using PPR piping",
      iconUrl: icon,
    },
    {
      title: "White / colored ceramic sanitaryware",
      iconUrl: icon,
    },
    {
      title: "Antiskid tiles for all toilets",
      iconUrl: icon,
    },
    {
      title:
        "Single lever diverter in shower areas & single lever basin tap mixer for all toilets",
      iconUrl: icon,
    },
    {
      title: "Floor mounted WC units with flush",
      iconUrl: icon,
    },
  ];
  const listDataColumns6 = [
    {
      title: "24/7 CCTV surveillance",
      iconUrl: icon,
    },
    {
      title: "Gated perimeter wall with electric fencing",
      iconUrl: icon,
    },
    {
      title: "Reputed security service",
      iconUrl: icon,
    },
    {
      title: "Ample parking space",
      iconUrl: icon,
    },
    {
      title: "Cabro paved parking",
      iconUrl: icon,
    },
  ];
  const itemObject = {
    fontSize: 18,
    iconColor: "yellow",
    iconBackground: "#e7ab25",
    iconRadius: "100%",
    fontWeight: "500",
    labelColor: colors.brown,
    containerHeight: "24px",
    containerWidth: "24px",
    iconHeight: "12px",
    iconWidth: "12px",
    itemSpacing: "16px",
    itemMargin: "8px 0",
    blurBackground: "#e7ab2566",
  };
  const headerHeading = "Master Homes";
  const headerDescription =
    "Naturally situated at an elevation & 70 mtrs. from Nakuru-Kabarak Road.\nNestled away from traffic & noisy polluted roads of the city, lift yourself by moving to the neighbourhood of Zaburi, Kiamunyi Residential Area with the comfort of being connected to the most developed areas of the city for work or leisure.";
  return (
    <React.Fragment>
      <HeaderContainer>
        <Heading>
          <HeaderContainerImage src={arrowImage} rotate={"rotate(0deg)"} />
          <HorizontalSpacer />
          {headerHeading}
          <HorizontalSpacer />
          <HeaderContainerImage src={arrowImage} rotate={"rotate(180deg)"} />
        </Heading>
        {headerDescription.split("\n").map((str) => (
          <Desrciption>{str}</Desrciption>
        ))}
        <VerticalSpacer />
        <Desrciption>
          80% of the flats sold and occupied. Buyers from the US and Middle
          East, fetching handsome rental returns. <br />
          Our tenants are white collar executives and some bankers and even from
          the UN.
        </Desrciption>
        <VerticalSpacer />
        <VideoContainer>
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/frsovZHhP4I?rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            style={{ borderRadius: "50px" }}
          />
        </VideoContainer>
        <VerticalSpacer />
        <DownloadBrocherButton
          onClick={() =>
            openBrochuer(
              "https://firebasestorage.googleapis.com/v0/b/master-homes-mmb.appspot.com/o/brochure%2FMASTER%20HOMES%20BROCHURE.pdf?alt=media&token=41426837-9192-4525-9b0d-a4497ad922be"
            )
          }
        >
          <DownloadBrocherButtonIcon src={downloadImage} />
          <HorizontalSpacer />
          <DownloadBrocherButtonText>
            Download Brochure
          </DownloadBrocherButtonText>
        </DownloadBrocherButton>
        <VerticalSpacer />
        <GridView
          desktopColumns={3}
          tabletColumns={2}
          phoneColumns={1}
          children={[
            <ListComponent
              alignment={"center"}
              key={strategicLocations1}
              margin={"28px 0"}
              listData={strategicLocations1}
              itemObject={itemObject}
              header={"Strategic Location"}
            />,
            <ListComponent
              alignment={"center"}
              key={strategicLocations2}
              margin={"28px 0"}
              listData={strategicLocations2}
              itemObject={itemObject}
              header={"Other nearby locations"}
            />,
          ]}
        />
        <VerticalSpacer />
        <VerticalSpacer />
        <Desrciption>
          Hospitals, CBD, Petrol Stations, Shopping Malls, Churches & other
          Educational Institutes, all available within 5km radius.
        </Desrciption>
      </HeaderContainer>
      <GallerySection data={steelFabricationGalleryImages} />
      <HeaderContainer leftColor={colors.white} rightColor={colors.white}>
        <VerticalSpacer />
        <Heading fontSize={32}>
          <HeaderContainerImage
            width={"60px"}
            src={arrowImage}
            rotate={"rotate(0deg)"}
          />
          <HorizontalSpacer />
          Salient Features
          <HorizontalSpacer />
          <HeaderContainerImage
            width={"60px"}
            src={arrowImage}
            rotate={"rotate(180deg)"}
          />
        </Heading>
        <VerticalSpacer />
        {/* <VideoContainer>
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/_cCrp8gDsVA`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            style={{ borderRadius: "50px" }}
          />
        </VideoContainer> */}
        {/* <VerticalSpacer />
        <VerticalSpacer /> */}
        <GridView
          desktopColumns={3}
          tabletColumns={2}
          phoneColumns={1}
          children={[
            <ListComponent
              key={listDataColumns1}
              margin={"28px 0"}
              listData={listDataColumns1}
              itemObject={itemObject}
              header={"Construction & Landscape"}
            />,
            <ListComponent
              key={listDataColumns2}
              margin={"28px 0"}
              listData={listDataColumns2}
              itemObject={itemObject}
              header={"Flooring, Walls & Ceiling"}
            />,
            <ListComponent
              key={listDataColumns3}
              margin={"28px 0"}
              listData={listDataColumns3}
              itemObject={itemObject}
              header={"Doors & Windows"}
            />,
            <ListComponent
              key={listDataColumns4}
              margin={"28px 0"}
              listData={listDataColumns4}
              itemObject={itemObject}
              header={"Kitchen"}
            />,
            <ListComponent
              key={listDataColumns5}
              margin={"28px 0"}
              listData={listDataColumns5}
              itemObject={itemObject}
              header={"Bathroom & Toilets"}
            />,
            <ListComponent
              key={listDataColumns6}
              margin={"28px 0"}
              listData={listDataColumns6}
              itemObject={itemObject}
              header={"Security & Parking"}
            />,
          ]}
        />
        <VerticalSpacer />
      </HeaderContainer>
      <ContactBanner title={"Say hello to elegant living"} />
    </React.Fragment>
  );
}
