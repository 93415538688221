import React, { useEffect, useState } from "react";
import BannerSection from "../../banner/bannerSection";
import ClientsSection from "../../clients/clientsSection";
import TestimonialSection from "../../testimonials/testimonialSection";
import AboutSection from "../../about/aboutSection";
import ServiceSection from "../../services/serviceSection";
import ContactBanner from "../../contactBanner/contactBanner";
import { colors } from "../../../utils/theme";

import {
  getBannerImages,
  getTestimonials,
} from "../../../services/firebaseService";
import { VerticalSpacer } from "../../footer/footerStyle";
import ChoiceComponent from "../../choiceComponent/choiceComponent";
import StickyContact from "../../../componentsRepo/stickyContact/stickyContact";

export default function HomePage({ ref }) {
  const [bannerImages, setBannerImagesState] = useState([]);
  const [testimonials, setTestimonialsState] = useState([]);
  const setBannerImages = async () => {
    const images = await getBannerImages();
    setBannerImagesState(images);
  };
  const setTestimonials = async () => {
    const images = await getTestimonials();
    setTestimonialsState(images);
  };
  useEffect(() => {
    setBannerImages();
    setTestimonials();
  }, []);
  var c =
    "linear-gradient(to right," +
    colors.primaryLight +
    ", " +
    colors.primaryDark +
    ")";
  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: c,
        }}
      >
        <BannerSection data={bannerImages} />
        <AboutSection />
        <ChoiceComponent />
      </div>
      <ServiceSection />
      <VerticalSpacer margin={"2% 0"} />
      {/* <ClientsSection /> */}
      {/* <TestimonialSection data={testimonials} /> */}
      <ContactBanner title={"Experience your dream home today."} />
      <StickyContact/>
    </React.Fragment>
  );
}
