import styled from "styled-components";
export const FABRoot = styled.div `
	background-color: #ffffff22;
	border-radius: 48%;
	padding: ${({ padding }) => (padding ? padding : "2px")};
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	bottom: 32px;
	right: ${({ right }) => (right ? right : null)};
	left: ${({ left }) => (left ? left : null)};
	z-index: 800;
	box-shadow: 0 3px 10px rgb(0, 0, 0,  0.3);
	@media screen and (max-width: 768px) {
		right: ${({ right }) => (right ? right : null)};
		left: ${({ left }) => (left ? left : null)};
		bottom:32px;
		padding: ${({ padding }) => (padding ? padding : "2px")};
	}
`;
export const FABImg = styled.img `
	height: 40px;
	width: 40px;
	@media screen and (max-width: 768px) {
		height: 42px;
		width: 42px;
	}
`;
