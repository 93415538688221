import styled from "styled-components";

export const ServiceImageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: ${({ reverse }) => (reverse ? "flex-end" : "flex-start")};
  flex-basis: 50%;
  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-basis: 100%;
  }
`;
export const ServiceImg = styled.img`
  width: 80%;
  @media screen and (max-width: 768px) {
    width: 75%;
  }
  @media screen and (max-width: 540px) {
    width: 100%;
  }
`;
export const CustomServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 50%;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
`;
export const ExtraService = styled.div`
  display: grid;
  grid-column-gap: 1%;
  grid-row-gap: 3%;
  width: 100%;
  padding: ${({ padding }) => (padding ? padding : "2%")};
  max-width: 100%;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
export const HorizontalSpacer = styled.span`
  width: ${({ width }) => (width ? width : "0px")};
`;
export const VerticalSpacer = styled.div`
  height: ${({ height }) => (height ? +height + "px" : "0px")};
  @media screen and (max-width: 768px) {
    height: ${({ height }) => (height ? height * 12 + "px" : "0px")};
  }
  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
