import styled from "styled-components";
import { colors } from "../../utils/theme";

export const TestimonialParent = styled.div`
  display: flex;
  ${"" /* flex-direction: column; */}
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const CarouselParent = styled.div`
  ${
    "" /* display: flex;
	justify-content:center;
	align-items: stretch; */
  }
  background-image: linear-gradient(
		to right,
		${colors.primaryLight},
		${colors.primaryDark}
	  );
`;
