import styled from "styled-components";

export const HeaderContainer = styled.div`
	position: relative;
	width: 100%;
	background-image: linear-gradient(to right, #225d65, #0d3336);
	padding: 4% 8%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`;

export const Heading = styled.div`
	font-size: 48px;
	color: white;
	margin-bottom: 2%;
	@media screen and (max-width: 768px) {
		font-size: 32px;
		text-align: center;
	}
	@media screen and (max-width: 540px) {
		text-align: left;
		font-size: 28px;
	}
`;
export const Desrciption = styled.div`
	font-size: 20px;
	color: white;
	@media screen and (max-width: 768px) {
		font-size: 18px;
		text-align: center;
	}
	@media screen and (max-width: 540px) {
		text-align: left;
		font-size: 16px;
	}
`;


export const ListColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: fit-content;
	@media screen and (max-width: 768px) {
	align-items: center;
	}
	@media screen and (max-width: 540px) {
	align-items: flex-start;
	}
`;

export const VerticalSpacer = styled.span`
	height: 32px;
`;
