import React from "react";
import masterHomesImage from "../../assets/apartment_option.png";
import masterVillasImage from "../../assets/villa_option.png";
import arrowImage from "../../assets/arrow.svg";
import {
  Choice,
  ChoiceComponentParent,
  ChoiceContainer,
  ChoiceImage,
  ChoicesRow,
  ChoiceTitle,
  Description,
  Header,
  HorizontalSpacer,
  TitleContainerImage,
  VerticalSpacer,
} from "./choiceComponentStyle";
import { Link } from "react-scroll";
import InlineButton from "../../componentsRepo/inlineButton/inlineButton";
import { colors } from "../../utils/theme";

export default function ChoiceComponent() {
  return (
    <React.Fragment>
      <ChoiceComponentParent>
        {/* <VerticalSpacer spacerHeight={"24px"}/> */}
        <Header>Looking for your new abode?</Header>
        <div style={{ height: "4px" }} />
        <Description>Come home to luxurious places</Description>
        <div style={{ height: "24px" }} />
        <ChoiceContainer>
          <ChoicesRow>
            <Link to={"apartments"}>
              <Choice>
                <ChoiceImage src={masterHomesImage} alt="" />
                {/* <ChoiceTitle>
                <TitleContainerImage
                src={arrowImage}
                rotate={"rotate(0deg)"}
                width={"32px"}
                />
                <HorizontalSpacer />
                Master Homes
                <HorizontalSpacer />
                <TitleContainerImage
                src={arrowImage}
                rotate={"rotate(180deg)"}
                width={"32px"}
                />
              </ChoiceTitle> */}
              <VerticalSpacer spacerHeight={"4px"}/>
                <InlineButton
                  showOptionText={false}
                  buttonColor={colors.accent}
                  textColor={colors.white}
                  isColoredIcon={false}
                />
              </Choice>
            </Link>
            <HorizontalSpacer spacerWidth={"24px"} />
            <Link to={"villas"}>
              <Choice>
                <ChoiceImage src={masterVillasImage} alt="" />
                {/* <ChoiceTitle>
                <TitleContainerImage
                src={arrowImage}
                rotate={"rotate(0deg)"}
                width={"32px"}
                />
                <HorizontalSpacer />
                Master Villas
                <HorizontalSpacer />
                <TitleContainerImage
                src={arrowImage}
                rotate={"rotate(180deg)"}
                width={"32px"}
                />
              </ChoiceTitle> */}
              <VerticalSpacer spacerHeight={"4px"}/>
                <InlineButton
                  showOptionText={false}
                  buttonColor={colors.accent}
                  textColor={colors.white}
                  isColoredIcon={false}
                />
              </Choice>
            </Link>
          </ChoicesRow>
        </ChoiceContainer>
      </ChoiceComponentParent>
    </React.Fragment>
  );
}
