import styled from "styled-components";
import { colors } from "../../utils/theme";
export const ListParentContiner = styled.div``;
export const ListParent = styled.div`
  width: 100%;
  flex: 1;
  height: fit-content;
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${({ alignment }) => (alignment ? alignment : "flex-start")};
  color: ${colors.brown};
  @media screen and (max-width: 768px) {
    margin: ${({ margin }) => (margin ? margin : "0")};
  }
  @media screen and (max-width: 540px) {
    margin: ${({ margin }) => (margin ? margin : "0")};
    align-items: flex-start;
  }
`;
export const ListHeader = styled.div`
  width: 100%;
  flex: 1;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 24px;
  font-weight: bold;
  text-align: left;
  color: ${colors.brown};
  @media screen and (max-width: 768px) {
    margin: ${({ margin }) => (margin ? margin : "0")};
  }
  @media screen and (max-width: 540px) {
    margin: ${({ margin }) => (margin ? margin : "0")};
    margin-left: 10px;
  }
`;
