import React from "react";
import rentHome from "../../assets/rent_home.svg";
import buyHome from "../../assets/buy_home.svg";
import {
  ActionButtonImage,
  ActionButtonParent,
  ActionButtonTitle,
  HorizontalSpacer,
  InlineButtonParent,
  InlineButtons,
  VerticalSpacer,
} from "./inlineButtonStyle";
import { useNavigate } from "react-router-dom";

export default function InlineButton({
  showOptionText = true,
  buttonColor,
  textColor,
  isColoredIcon = true,
  padding,
}) {
  return (
    <React.Fragment>
      <InlineButtonParent>
        <InlineButtons>
          <SVGButton
            isColoredIcon={isColoredIcon}
            textColor={textColor}
            buttonColor={buttonColor}
            image={buyHome}
            label={"Buy Now"}
            iconWidth={"28"}
            padding={padding}
          />
          <HorizontalSpacer spacerWidth={"8px"} />
          {/* OR <HorizontalSpacer spacerWidth={"8px"} />
          <SVGButton
            isColoredIcon={isColoredIcon}
            textColor={textColor}
            buttonColor={buttonColor}
            image={rentHome}
            label={"Rent"}
            iconWidth={"24"}
            padding={padding}
          /> */}
        </InlineButtons>
      </InlineButtonParent>
    </React.Fragment>
  );
}

function SVGButton({ image, label, iconWidth, buttonColor, textColor,isColoredIcon,padding }) {
  const history = useNavigate();
  const buttonClick = (path) => {
    history(path);
  };
  return (
    <ActionButtonParent
      buttonColor={buttonColor}
      textColor={textColor}
      onClick={() => buttonClick("/contact")}
      padding={padding}
    >
      <ActionButtonImage isColoredIcon={isColoredIcon} src={image} alt="" width={iconWidth} />
      <HorizontalSpacer spacerWidth={"6px"} />
      <ActionButtonTitle>{label}</ActionButtonTitle>
    </ActionButtonParent>
  );
}
