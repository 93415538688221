import React from "react";
import { useNavigate } from "react-router-dom";
import CardComponent from "../../componentsRepo/contentCard/contentCard";
import FlexView from "../../componentsRepo/flexViewComponent/flexView";
import { colors } from "../../utils/theme";
import StatsComponent from "./statsComponent/statsComponent";

const aboutUsObject = {
  cardPadding: "4% 4% 4% 6%",
  itemSpacing: "16px",
  bgColor: "#00000000",
  alignItems: "flex-start",
  tagText: "ABOUT US",
  tagTextSize: 18,
  tagTextColor: colors.brown,
  tagFontWeight: "200",
  titleText: "Who we are?",
  titleTextSize: 36,
  titleTextColor: colors.brown,
  titleFontWeight: "900",
  descriptionText:
    "MASTER HOMES is a leading and reputable Building Developer and Promoter Company, serving the fast growing City of Nakuru in Kenya, for the ever growing demand for housing needs. Ever since we opened for business, we’ve taken a comprehensive approach to project management, providing our clients with a wide range of choice from elegant apartments to wholly owned comfort villas. Get in touch with our sales team today, to learn more about what we can offer you, to best serve your need.",
  descriptionTextSize: 20,
  descriptionTextColor: colors.brown,
  descriptionFontWeight: "300",
  buttonTextColor: colors.white,
  buttonText: "Inquire Now",
  buttonTextSize: 22,
  buttonFontWeight: "300",
  buttonFillColor: colors.accent,
  buttonRadius: "100px",
  buttonPadding: "8px 24px",
  buttonBorderStyle: "3px solid ${colors.primaryColor}",
  desktopWeight: 2,
  tabletWeight: 2,
  phoneWeight: 1,
};

export default function AboutSection() {
  const history = useNavigate();
  const inquireNowButtonClick = (path) => {
    history(path);
  };
  return (
    <div id={"about"}>
      <FlexView
        child={[
          <CardComponent
            {...aboutUsObject}
            onCallback={() => inquireNowButtonClick("/contact")}
            key={"about"}
          />,
          // <StatsComponent key={"stats"} />,
        ]}
      />
    </div>
  );
}
