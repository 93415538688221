import React from "react";
import { colors, fonts } from "../../utils/theme";
import { useNavigate } from "react-router-dom";

export default function StickyContact() {
  const history = useNavigate();
  const navigateToContact = (path) => {
    history(path);
  };
  return (
    <React.Fragment>
      <marquee
        style={{
          backgroundColor: colors.accent,
          color: colors.white,
          height: "28px",
          position: "fixed",
          display: "flex",
          zIndex: "800",
          bottom: "0px",
          width: "100%",
          fontSize: "14px",
          alignItems: "center",
          fontFamily: fonts.garamond,
        }}
        onClick={() => navigateToContact("/contact")}
      >
        MMB..... PREMIUM LIVING at AFFORDABLE PRICES
      </marquee>
    </React.Fragment>
  );
}
