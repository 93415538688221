import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardComponent from "../../componentsRepo/contentCard/contentCard";
import FlexView from "../../componentsRepo/flexViewComponent/flexView";
// import interiorService from "../../assets/InteriorDesignImage.svg";
// import steelService from "../../assets/SteelStructuresImage.svg";
// import solarService from "../../assets/SolarPanelsImage.svg";
import apartmentServiceImage from "../../assets/apartmentService.png";
import villaServiceImage from "../../assets/villaService.png";
import {
  CustomServiceContainer,
  ServiceImageContainer,
  ServiceImg,
  VerticalSpacer,
  ExtraService,
} from "./serviceSectionStyle";
import { colors } from "../../utils/theme";
import { color } from "@mui/system";
import InlineButton from "../../componentsRepo/inlineButton/inlineButton";
const serviceOneObject = {
  cardPadding: "8% 0%",
  itemSpacing: "1%",
  bgColor: "white",
  alignItems: "flex-start",
  tagText: "MASTER HOMES",
  tagTextSize: 28,
  tagTextColor: colors.brown,
  tagFontWeight: "200",
  titleText: "Live above the rest of the world",
  titleTextSize: 36,
  titleTextColor: colors.brown,
  titleFontWeight: "900",
  descriptionText:
    "A perfect blend of elegance and comfort, within the buzz of the city & yet without the turmoil it throws up. \nNestled away from traffic & noisy polluted roads of the city, lift yourself by moving to the neighbourhood of Zaburi, Kiamunyi Residential Area with the comfort of being connected to the most developed areas of the city for work or leisure.\nRadiating an aura of contemporary architecture, with nature as your neighbour to revive the pleasure of stylish living & enjoy a premium lifestyle.\n70 mtrs. from Nakuru-Kabarak Road.",
  descriptionTextSize: 20,
  descriptionTextColor: colors.brown,
  descriptionFontWeight: "200",
  buttonTextColor: colors.white,
  buttonText: "Learn More",
  buttonTextSize: 22,
  buttonFontWeight: "300",
  buttonFillColor: colors.accent,
  buttonRadius: "100px",
  buttonPadding: "8px 24px",
  buttonBorderStyle: "3px solid ${colors.primaryColor}",
  desktopWeight: 3,
  tabletWeight: 2,
  phoneWeight: 1,
  showTagImage: true,
  tagImageWidth: 55,
  isBlinkingEnabled:true,
};
const serviceTwoObject = {
  cardPadding: "8% 0 4% 0",
  bgColor: "white",
  itemSpacing: "1%",
  alignItems: "flex-start",
  tagText: "MASTER VILLAS",
  tagTextSize: 28,
  tagTextColor: colors.brown,
  tagFontWeight: "200",
  titleText: "Don't just visualize your dream home. Experience it !",
  titleTextSize: 36,
  titleTextColor: colors.brown,
  titleFontWeight: "700",
  descriptionText:
    "With our beautiful 3 BHK villas in Nakuru. Kenya.\nBest possible construction quality & unique design, crafted to perfection. Each villa is painted in different colors to accentuate the beauty. A perfect place to relax and rejuvenate.\nIn the 1st phase of 8 villas, customers have already purchased 2 in pre-booking.",
  descriptionTextSize: 22,
  descriptionTextColor: colors.brown,
  descriptionFontWeight: "200",
  buttonTextColor: colors.white,
  buttonText: "Learn More",
  buttonTextSize: 22,
  buttonFontWeight: "300",
  buttonFillColor: colors.accent,
  buttonRadius: "100px",
  buttonPadding: "8px 24px",
  buttonBorderStyle: "3px solid ${colors.primaryColor}",
  desktopWeight: 3,
  tabletWeight: 2,
  phoneWeight: 2,
  showTagImage: true,
  tagImageWidth: "55px",
  isBlinkingEnabled:true,
};
const customServiceDataOne = {
  cardPadding: "1% 2%",
  bgColor: "white",
  itemSpacing: "2%",
  alignItems: "flex-start",
  titleText: "Features",
  titleTextSize: 24,
  titleTextColor: "#a4a4a4",
  titleFontWeight: "700",
  descriptionText:
    "Roofs for optimum water harvesting.\nWater proofed houses from foundation.\nA gated community plot with electric fence.\nIndividual landscaped garden & 3 cars parking.\n10000 ltrs underground water tanks, pump house & 2 separate overhead tanks.\nClear title deeds.",
  descriptionTextSize: 18,
  descriptionTextColor: "#000000",
  descriptionFontWeight: "300",
  hoverStyle:
    "0 1px 4px rgba(236, 236, 236, 1), 0 0 18px rgba(236, 236, 236, 1)",
  desktopWeight: 5,
  tabletWeight: 3,
  phoneWeight: 3,
};
const customServiceDataTwo = {
  cardPadding: "1% 2%",
  bgColor: "white",
  itemSpacing: "2%",
  alignItems: "flex-start",
  titleText: "Location",
  titleTextSize: 24,
  titleTextColor: "#a4a4a4",
  titleFontWeight: "700",
  descriptionText:
    "1.6 km Kiroban High School.\n2.4 km Trinity Academy Primary School.\n1.2 km Lake Oil Petrol Station.\n1.3 km Alpacino's Garden Lounge Grill.\n1.4 km Ganglong International Company",
  descriptionTextSize: 18,
  descriptionTextColor: "#000000",
  descriptionFontWeight: "300",
  hoverStyle:
    "0 1px 8px rgba(236, 236, 236, 1), 0 0 18px rgba(236, 236, 236, 1)",
  desktopWeight: 5,
  tabletWeight: 3,
  phoneWeight: 3,
};
const customServiceDataThree = {
  cardPadding: "1% 2%",
  bgColor: "white",
  itemSpacing: "2%",
  alignItems: "flex-start",
  titleText: "03",
  titleTextSize: 36,
  titleTextColor: "#a4a4a4",
  titleFontWeight: "700",
  descriptionText:
    "Steel Cupboards\nChairs\nCots\nWindow Grills\nStaircase & Railings",
  descriptionTextSize: 16,
  descriptionTextColor: "#000000",
  descriptionFontWeight: "300",
  hoverStyle:
    "0 1px 4px rgba(236, 236, 236, 1), 0 0 18px rgba(236, 236, 236, 1)",
  desktopWeight: 5,
  tabletWeight: 3,
  phoneWeight: 3,
};
const customServiceDataFour = {
  cardPadding: "1% 2%",
  bgColor: "white",
  itemSpacing: "2%",
  alignItems: "flex-start",
  titleText: "04",
  titleTextSize: 36,
  titleTextColor: "#a4a4a4",
  titleFontWeight: "700",
  descriptionText:
    "Industrial / Textile / Pharmaceutical Trolleys\nRolling Shutters\nMaterial Handling Systems",
  descriptionTextSize: 16,
  descriptionTextColor: "#000000",
  descriptionFontWeight: "300",
  hoverStyle:
    "0 1px 4px rgba(236, 236, 236, 1), 0 0 18px rgba(236, 236, 236, 1)",
  desktopWeight: 5,
  tabletWeight: 3,
  phoneWeight: 3,
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function ServiceImage({ imageSrc, reverse }) {
  return (
    <ServiceImageContainer reverse={reverse}>
      <ServiceImg src={imageSrc} alt="" />
    </ServiceImageContainer>
  );
}
function CustomServiceCard({ onClick }) {
  const [width] = useWindowSize();
  return (
    <CustomServiceContainer>
      <CardComponent
        {...serviceTwoObject}
        onCallback={() => onClick()}
        showMoreOptions={true}
      />
      {width >= 1200 || width < 786 ? (
        <ExtraService>
          <CardComponent {...customServiceDataOne} />
          <CardComponent {...customServiceDataTwo} />
          {/* <CardComponent {...customServiceDataThree} />
          <CardComponent {...customServiceDataFour} /> */}
        </ExtraService>
      ) : null}
    </CustomServiceContainer>
  );
}

export default function ServiceSection() {
  const history = useNavigate();
  const interiorServiceClick = (path) => {
    history(path);
  };
  const [width] = useWindowSize();
  return (
    <React.Fragment>
      <VerticalSpacer height={"4"} />
      <div id={"apartments"}>
        <FlexView
          padding={"0 6%"}
          reverse={true}
          child={[
            <ServiceImage
              imageSrc={apartmentServiceImage}
              reverse={true}
              key={"interiorService"}
            />,
            <CardComponent
              {...serviceOneObject}
              key={"interiorServiceImage"}
              onCallback={() => interiorServiceClick("/homes")}
              showMoreOptions={true}
            />,
          ]}
        />
      </div>
      <VerticalSpacer height={1} />
      <div id={"villas"}>
        <FlexView
          padding={"0% 6%"}
          reverse={false}
          child={[
            <ServiceImage
              imageSrc={villaServiceImage}
              reverse={false}
              key={"steelService"}
            />,
            <CustomServiceCard
              onClick={() => interiorServiceClick("/villas")}
              key={"steelServiceImage"}
            />,
          ]}
        />
        {width <= 1200 && width > 786 ? (
          <ExtraService padding={"0 8%"}>
            <CardComponent {...customServiceDataOne} />
            <CardComponent {...customServiceDataTwo} />
            {/* <CardComponent {...customServiceDataThree} />
          <CardComponent {...customServiceDataFour} /> */}
          </ExtraService>
        ) : null}
      </div>
      <VerticalSpacer height={4} />
    </React.Fragment>
  );
}
